import Aos from 'aos';

window.AnimateOnScroll = function () {
  this.init = function () {
    Aos.init({
      once: true
    });
  }
};

new AnimateOnScroll().init();
